import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic01 from '../assets/images/bunded/1.jpeg'
import pic03 from '../assets/images/bunded/3.jpeg'
import pic10 from '../assets/images/bunded/10.jpeg'
import pic11 from '../assets/images/bunded/11.jpeg'
import pic12 from '../assets/images/bunded/12.jpeg'
import pic13 from '../assets/images/bunded/13.jpeg'
import pic14 from '../assets/images/bunded/14.jpeg'
import pic15 from '../assets/images/bunded/15.jpeg'
import pic16 from '../assets/images/bunded/16.jpeg'
import pic111 from '../assets/images/bunded/111.jpeg'
import pic222 from '../assets/images/bunded/222.jpeg'
import pic333 from '../assets/images/bunded/333.jpeg'
import pic444 from '../assets/images/bunded/444.jpeg'
import pic555 from '../assets/images/bunded/555.jpeg'
import pic666 from '../assets/images/bunded/666.jpeg'
import pic777 from '../assets/images/bunded/777.jpeg'
import pic888 from '../assets/images/bunded/888.jpeg'
import pic999 from '../assets/images/bunded/999.jpeg'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const Bunded = (props) => (
    <Layout>
        <Helmet>
            <title>Bunded Tanks</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        {/* <BannerLanding /> */}

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Bunded Tanks</h2>
                    </header>
                </div>
            </section>

            <Slider {...settings}>
                <div className="image">
                    <img src={pic03} alt="" />
                </div>
                <div className="image">
                    <img src={pic01} alt="" />
                </div>
                <div className="image">
                    <img src={pic10} alt="" />
                </div>
                <div className="image">
                    <img src={pic11} alt="" />
                </div>
                <div className="image">
                    <img src={pic12} alt="" />
                </div>
                <div className="image">
                    <img src={pic13} alt="" />
                </div>
                <div className="image">
                    <img src={pic14} alt="" />
                </div>
                <div className="image">
                    <img src={pic15} alt="" />
                </div>
                <div className="image">
                    <img src={pic16} alt="" />
                </div>
                <div className="image">
                    <img src={pic111} alt="" />
                </div>
                <div className="image">
                    <img src={pic222} alt="" />
                </div>
                <div className="image">
                    <img src={pic333} alt="" />
                </div>
                <div className="image">
                    <img src={pic444} alt="" />
                </div>
                <div className="image">
                    <img src={pic555} alt="" />
                </div>
                <div className="image">
                    <img src={pic666} alt="" />
                </div>
                <div className="image">
                    <img src={pic777} alt="" />
                </div>
                <div className="image">
                    <img src={pic888} alt="" />
                </div>
                <div className="image">
                    <img src={pic999} alt="" />
                </div>
            </Slider>

            <section id="two" className="spotlights">
                <section>
                    <div className="content">
                        <div className="inner">
                            {/* <header className="major">
                                <h3>Orci maecenas</h3>
                            </header> */}
                            <p>Store petrol on your premises legally and securely with our range of fully bunded and fire-protected above ground petrol storage tanks. Our tanks range from 1200 litres upwards. Bunded tanks can also be supplied complete with security cabinets to accommodate equipment such as diesel and gas oil pumps, fuel management systems, tank gauges and other associated control equipment</p>

                            <p>Our bunded tanks can also be fitted with shutters and security locks to ensure security.</p>

                            <p>All our tanks are built to order allowing us to cater to your specific requirements.</p>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Bunded